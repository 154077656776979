@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.filter {
  width: 100%;
  background-color: #151515;

  padding: 10px 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  .search {
    max-width: 466px;
    margin: 6px 12px;
  }
  .select {
    max-width: 466px;
    height: 48px;
    margin: 6px 12px;
  }
}

.sortWrap {
  @include flexbox();
  overflow: hidden;
  height: 0;
  display: flex;
  padding: 0 18px;
  width: 100%;
  transition: height $animation-time-long, padding $animation-time-long;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
}

.sort {
  @include flexbox();
  max-width: 958px;
  padding-top: 20px;
  width: 100%;
  flex-direction: column;

  @include tablet-big {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}

.sortOpen {
  height: 116px;
  padding-top: 2px;

  @include tablet-big {
    flex-direction: row;
    height: 60px;
  }
}

.sortButton {
  @include tablet-big {
    margin-bottom: 0;
  }
  max-width: 466px;
  margin-bottom: 20px;
}

.filterWrap {
  @include flexbox();
  flex-direction: column;
}
