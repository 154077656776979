@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.carousel {
  width: 100%;
  background-image: url("../../../assets/images/lending/buyCrosscar/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .slide {
    &Wrapper {
      height: 554px;
      padding: 64px 16px;

      @include tablet {
        padding: 64px 24px;
      }
    }
    height: 100%;
    width: 100%;
    max-width: 720px;

    margin: 0 auto;

    border-radius: 10px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include tablet-big {
      filter: blur(4px);
    }

    transition: filter 0.5s ease-in-out;
    &CP {
      background-image: url("../../../assets/images/lending/carousel/gallerySliderCP.png");
    }
    &TM {
      background-image: url("../../../assets/images/lending/carousel/gallerySliderTM.png");
    }
    &AS {
      background-image: url("../../../assets/images/lending/carousel/gallerySliderAS.png");
    }
  }

  .dot {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px solid transparent;
    padding: 2px;
    &Inner {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: white;
    }
  }
  :global(.slick-dots) {
    bottom: 26px;
    line-height: 0;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    li {
      width: 16px;
      height: 16px;
      margin: 0 8px;
    }
  }
  :global(.slick-active) {
    .dot {
      border-color: #FFFFFF;
    }
    .slide {
      filter: blur(0);
    }
  }
}
