@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.footer {
  width: 100%;
  padding: 28px 18px;
  @include flexbox(center, center);

  @include mobile {
    @include flexbox(center, space-between);
    padding: 28px 24px;
  }

  @include desktop-min {
    padding: 28px 34px;
  }
}

.text {
  width: 222px;
  font-size: $font-size-text-sm;
  color: $color-pink;
  font-weight: 400;
  cursor: pointer;
  display: none;

  @include mobile {
    display: block;
  }

  @include tablet {
    margin: 0;
  }
}

.logo {
  display: none;
  width: 100px;

  @include tablet {
    display: block;
  }
}