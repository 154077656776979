@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.rollingBlock {
  min-height: 22px;
  overflow: hidden;
  max-height: 22px;
  color: #FF7353;
  transition: height $animation-time, max-height $animation-time;

  @include mobile {
    max-height: 26px;
  }

  @include tablet-big {
    max-height: 36px;
  }
}

.header {
  @include flexbox(flex-start, space-between);
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
}

.title {
  padding: 10px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #FF7353;
  margin: 0 22px 0 0;
  text-align: left;

  @include mobile {
    font-size: 20px;
    line-height: 26px;
  }

  @include tablet {
    font-size: 22px;
    line-height: 26px;
  }

  @include tablet-big {
    font-size: 26px;
    line-height: 36px;
  }
}

.text {
  font-size: 16px;
  line-height: 22px;
  color: #FAFAFA;
  @include tablet-big {
    font-size: 18px;
    line-height: 26px;
  }
}

.plus {
  margin-top: 9px;
  min-width: 24px;
  min-height: 24px;
  position: relative;
  border-radius:15px;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background: #FF7353;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -30%;
  }
  &::after {
    transform: rotate(90deg);
    transition: $animation-time ease;
  }

  @include mobile {
    margin-top: 11px;
  }

  @include tablet-big {
    margin-top: 16px;
  }
}

.isMinus {
  &::after {
    transform: rotate(0);
  }
}