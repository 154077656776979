@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  width: 100%;
  background: url('../../../assets/images/gamefi/gamefi_03_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  justify-content: center;
  min-height: 760px;
  z-index: 4;
  position: relative;

  @include mobile {
    min-height: initial;
    background-position-x: -460px;
  }

  @include tablet {
    background-position-x: -380px;
  }

  @include tablet-header {
    background-position-x: center;
  }
}

.person {
  margin-top: auto;
  width: 315px;
  position: absolute;
  bottom: -55px;
  z-index: 5;

  @include mobile {
    width: 420px;
    position: relative;
    bottom: initial;
    z-index: initial;
  }
}

.staking {
  @include flexbox();
  flex-direction: column;
  position: absolute;
  align-items: center;
  min-height: 760px;
  justify-content: initial;
  margin-top: 20px;

  @include mobile {
    position: relative;
    margin-left: 3%;
    margin-right: 99px;
    margin-top: initial;
    margin-bottom: 80px;
    align-items: flex-start;
    min-height: initial;
    justify-content: center;
  }

  @include tablet {
    margin-left: 92px;
  }

  @include tablet-big {
    margin-right: initial;
  }
}

.title {
  font-weight: 400;
  margin-bottom: 5px;
  order: 0;

  @include mobile {
    margin-bottom: 40px;
    order: initial;
  }
}

.stakingImage {
  margin-bottom: 360px;
  width: 240px;
  order: 2;

  @include mobile {
    margin-bottom: 40px;
    width: 285px;
    order: initial;
  }
}

.text {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 17px;
  order: 1;

  @include mobile {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 39px;
    order: initial;
  }
}

.stakingButton {
  width: 240px;
  height: 48px;
}

.link {
  order: 3;
  z-index: 6;

  @include mobile {
    order: initial;
  }
}

