@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  width: 100%;
  background: url('../../../assets/images/lending/carousel/slide1.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 411px;
  justify-content: initial;
  z-index: 8;
  justify-content: center;
  flex-wrap: wrap;
  background-position: center;

  @include mobile-sm {
    flex-wrap: nowrap;
    min-height: 700px;
  }
}

.title {
  font-weight: 400;
  width: 210px;
  text-align: center;
  margin-bottom: 100px;

  @include mobile-sm {
    width: 316px;
    margin-left: 24px;
    margin-bottom: initial;
    text-align: initial;
  }

  @include tablet {
    width: 386px;
    margin-left: 64px;
    text-align: initial;
    margin-bottom: initial;
  }
}

.image {
  width: 210px;

  @include mobile-sm {
    width: 316px;
  }

  @include tablet {
    width: 386px;
  }
}
