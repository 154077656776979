@import '../../assets/styles/mixins';

.container {
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
  width: 100%;
}

.loader {
  @include flexbox();
  margin-top: 40px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
  display: none;
}

.loaderActive {
  display: flex;
}

.iframe {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
  display: none;
}

.iframeActive {
  display: flex;
}