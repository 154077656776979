@import '../../../../assets/styles/mixins';

.container {
  @include flexbox();
  position: relative;
  overflow: hidden;
  height: 258px;
  width: 100%;

  @include tablet-big {
    height: 344px;
  }
}

.planetWrap {
  position: absolute;
  top: 0;
}

.cloudsWrap {
  position: absolute;
  top: 0;
}

.rotateRight {
  transform:rotateZ(360deg);
  -webkit-transition-duration: 2s;
  transition-duration: 2s;
}

.rotateLeft {
  transform:rotateZ(-360deg);
  -webkit-transition-duration: 2s;
  transition-duration: 2s;
}

.planet {
  animation: rotateClouds 150s infinite linear;
  width: 560px;

  @include tablet-big {
    width: 692px;
  }
}

.clouds {
  animation: rotatePlanet 150s infinite linear;
  width: 560px;

  @include tablet-big {
    width: 692px;
  }
}


@keyframes rotatePlanet{
  0%{
    transform:rotateZ(0);
  }
  100%{
    transform:rotateZ(-360deg);
  }
}

@keyframes rotateClouds{
  0%{
    transform:rotateZ(0);
  }
  100%{
    transform:rotateZ(360deg);
  }
}
