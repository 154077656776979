@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  width: 100%;
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);
}

.wrap {
  @include flexbox();
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  padding: 0 34px;

  @include tablet-big {
    max-width: 960px;
  }
}

.title {
  margin: 68px 0 40px;

  @include tablet {
    margin: 68px 0 66px;
  }
}
