@import '../../../assets/styles/mixins';

.container {
  width: 100%;
  background: linear-gradient(90deg, #5C1521 0%, #701523 20.31%, #38172A 52.08%, #462484 81.25%, #3A2266 100%);
  height: 339px;
}

.wrap {
  @include flexbox();
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/gamefi/marketplace.png');
  background-repeat: no-repeat;
  background-position-x: -675px;

  @include mobile-sm {
    background-position-x: -560px;
  }

  @include tablet {
    background-position-x: -500px;
  }

  @include desktop-min {
    background-position-x: -300px;
  }

  @include desktop {
    background-position-x: -200px;
  }

  @include desktop-md {
    background-position-x: 0px;
  }

  @include desktop-2k {
    background-position-x: 250px;
  }

  @include desktop-4k {
    background-position-x: 550px;
  }
}
