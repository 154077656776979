@import '../../../../assets/styles/mixins';

.factory {
  padding-top: 45px;
  padding-bottom: 15px;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include tablet {
    padding: 120px 0;
  }

  &Title {
    margin-bottom: 40px;
    font-size: $font-size-title-xs;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      margin-bottom: 100px;
      font-size: $font-size-title-lg;
    }
  }

  &Cards {
    @include flexbox(center, center, row);
    flex-wrap: wrap;
    gap: 20px 0;

    @include tablet {
      gap: 20px;
    }

    @include tablet-big {
      margin: 0 auto;
      max-width: 962px;
    }
  }
}

.container {
  width: 100%;
  padding: 0 15px;
}

.card {
  @include flexbox(flex-start, space-between, column);
  padding: 15px 10px 10px;
  border-radius: $border-radius-lg;
  background: linear-gradient(-90deg, #3A2266 0%, #5C1521 100%);

  &Title {
    display: flex;
    align-items: center;
  }

  &Img {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  &Sum {
    position: relative;
    @include flexbox(center, flex-start);
    display: flex;
    margin-bottom: 10px;
    width: 100%;

    &Numb {
      padding-left: 20px;
      width: 143px;
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      color: inherit;
      background: #2C2C2C;
    }

    &Btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);

      &Disable {
        color: rgba(255, 255, 255, 0.5);
        background: linear-gradient(90deg, rgba(247, 10, 97, 0.5) 0%, rgba(211, 61, 163, 0.5) 50.38%, rgba(177, 107, 223, 0.5) 100%);
      }
    }
  }
}

.colorSize {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin: 9px 0;
}

.stakedInputWrap {
  @include flexbox(center, flex-start);
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.loader {
  padding-left: 20px;
  position: absolute;
}

.button {
  width: fit-content;
  margin-left: 1px;
  height: 48px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttonInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}