@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 37vw;
  min-height: 220px;
  max-height: 800px;
  background-image: url("../../../assets/images/lending/buyCrosscar/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.title {
  margin-top: 3%;
}

.subtitle {
  font-weight: 400;
}

.logoWrap {
  width: 100vw;
  transition: transform $animation-time;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.logo {
  width: 100vw;
}

.carContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.carL {
  width: 40vw;
  min-width: 100px;
  transform: translate(0px);
  transition: transform $animation-time-long, width $animation-time-long;
}

.carR {
  width: 43vw;
  min-width: 100px;
  transform: translate(0);
  transition: transform $animation-time-long, width $animation-time-long;
}

.carWrapper {
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  z-index: $confirm-alert-z-index;
}

.carWrapperL:hover .carL {
  @include tablet {
    width: 38vw;
    min-width: 230px;
    transform: translate(-10px);
  }
}

.carWrapperR:hover .carR {
  @include tablet {
    width: 41vw;
    transform: translate(10px);
  }
}

.carName {
  position: absolute;
  width: 40vw;
  transition: opacity $animation-time-long, transform $animation-time-long;
}

.carNameL {
  opacity: 0;
  left: 0;
  transform: translate(-40px);
}

.carNameR {
  opacity: 0;
  right: 0;
  transform: translate(40px);
}

.carWrapperL:hover .carNameL {
  @include tablet {
    opacity: 1;
    transform: translate(0);
  }
}

.carWrapperR:hover .carNameR {
  @include tablet {
    opacity: 1;
    transform: translate(0);
  }
}

.vsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: $tooltip-z-index;
}

.VS {
  width: 15vw;
}

.text {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: calc(1.5vw + 28px);

  @include tablet {
    font-size: 1.5vw;
  }

  @include tablet-big {
    margin-bottom: calc(1.5vw + 45px);
  }
}

.button {
  position: absolute;
  z-index: $toast-container-z-index;
  bottom: -28px;

  @include tablet-big {
    bottom: -45px;
  }
}

.linkCar {
  display: flex;
  align-items: flex-end;
}