@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.container {
  position: relative;
  min-width: 229px;
  min-height: 316px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  border-radius: 6px;

  @include tablet-big {
    min-width: 300px;
    min-height: 334px;
  }
}

.topBlock {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;

  @include tablet-big {
    padding-right: 14px;
  }
}

.img {
  position: absolute;
  width: 217px;
  top: -24px;
  left: -11px;
  @include tablet {
    left: -27px;
  }

  @include tablet-big {
    left: 0;
  }
}

.bottomBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  z-index: 232323;
  width: 100%;
  height: 91%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 16px 20px 16px;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  @include tablet-big {
    height: 100%;
    padding: 0 24px 24px 24px;
  }
}

.title {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;

  @include tablet-big {
    font-size: 24px;
    line-height: 36px;
  }
}

.text {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  @include tablet-big {
    font-size: 16px;
    line-height: 20px;
  }
}

.percent {
  font-family: Coffinstone;
  z-index: $toast-container-z-index;
  padding-top: 104px;
  margin-bottom: 0;
  font-size: 32px;
  line-height: 32px;

  @include tablet-big {
    padding-top: 80px;
    font-size: 40px;
    line-height: 36px;
  }
}