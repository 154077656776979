@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.container {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 670px;
  background-repeat: no-repeat;
  background-size: cover;

  .dot {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px solid transparent;
    padding: 2px;
    &Inner {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: white;
    }
  }
  :global(.slick-dots) {
    bottom: 50px;
    line-height: 0;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    li {
      width: 16px;
      height: 16px;
      margin: 0 8px;
    }
  }
  :global(.slick-active) {
    .dot {
      border-color: #FFFFFF;
    }
    .slide {
      filter: blur(0);
    }
  }
}

.slideWrap {
  display: flex !important;
  justify-content: flex-end;
  flex-direction: column;
  height: 670px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -280px;

  @include tablet-slider {
    flex-direction: row;
    background-position: -453px;
    align-items: center;
    justify-content: center;
  }


  @include desktop-min {
    background-position: bottom;
  }
}

.slide {

  @include tablet-slider {
    margin-left: 15%;
  }
}

.title {
  text-align: center;
  font-weight: 300;
  font-size: 48px;

  @include tablet-slider {
    text-align: left;
  }

  @include tablet-big {
    font-size: 64px;
  }
}

.text {
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin: 30px 0 114px;

  @include tablet-slider {
    text-align: left;
    margin-bottom: 0;
  }

  @include tablet-big {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 50px;
  }
}

.nextButton {
  right: 5%;

  @include desktop-sm-slider {
    right: 10%;
  }
}

.nextButton::after {
  content: "»";
  font-size: 74px;
  line-height: 36px;
  margin-left: 5px;
  font-weight: 500;
}

.prevButton {
  left: 5%;

  @include desktop-sm-slider {
    left: 10%;
  }
}

.prevButton::after {
  content: "«";
  font-size: 74px;
  line-height: 36px;
  margin-right: 5px;
  font-weight: 500;
}

.arrowBtn {
  position: absolute;
  top: calc(50% - 88px);
  z-index: 10;
  height: 88px;
  width: 88px;
  background: linear-gradient(180deg, #B169DD 0%, #F50C64 100%);
  border-radius: 100%;
}

.arrowBtn:hover {
  transform: scale(1.03);
  cursor: pointer;
}
