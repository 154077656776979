@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.container {
  @include flexbox();
  width: 100%;
}

.cardSumBtn {
  height: 48px;
  min-width: 158px;
  max-width: 158px;
}

.cardSumNumb {
  padding-left: 10px;
  color: $color-root;
}