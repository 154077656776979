@import './variables';

$defaultFlexboxAlign: center;
$defaultFlexboxJustify: center;
$defaultFlexboxDirection: row;

@mixin flexbox($align: $defaultFlexboxAlign, $justify: $defaultFlexboxJustify, $direction: $defaultFlexboxDirection) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

// Mixins for mobile-first template
@mixin mobile-sm {
  @media (min-width: $mobile-sm + px) {
    @content;
  }
}
@mixin mobile {
  @media (min-width: $mobile + px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet + px) {
    @content;
  }
}

@mixin tablet-slider {
  @media (min-width: $tablet-slider + px) {
    @content;
  }
}

@mixin tablet-header {
  @media (min-width: $tablet-header + px) {
    @content;
  }
}

@mixin tablet-big {
  @media (min-width: $tablet-big + px) {
    @content;
  }
}

@mixin desktop-min {
  @media (min-width: $desktop-min + px) {
    @content;
  }
}

@mixin desktop-sm {
  @media (min-width: $desktop-sm + px) {
    @content;
  }
}

@mixin desktop-sm-slider {
  @media (min-width: $desktop-sm-slider + px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop + px) {
    @content;
  }
}

@mixin desktop-md {
  @media (min-width: $desktop-md + px) {
    @content;
  }
}

@mixin desktop-big {
  @media (min-width: $desktop-big + px) {
    @content;
  }
}

@mixin desktop-2k {
  @media (min-width: $desktop-2k + px) {
    @content;
  }
}

@mixin desktop-4k {
  @media (min-width: $desktop-4k + px) {
    @content;
  }
}
