@import '../../../../assets/styles/mixins';

.container {
  @include flexbox();
  width: 100%;
  margin-bottom: 90px;
  flex-wrap: wrap;
  justify-content: space-around;

  @include tablet-big {
    justify-content: center;
  }
}

.cardWrap {
  @include flexbox();
  flex-direction: column;
  justify-content: space-between;
  max-width: 225px;
  height: 420px;
  margin-top: 40px;
  padding: 0 20px;

  @include tablet {
    max-width: 225px;
    height: 485px;
  }

  @include tablet-big {
    max-width: 300px;
    height: 475px;
  }
}

.cardTitleWrap {
  min-height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.cardImageWrap {
  height: 195px;
  width: 225px;

  @include tablet {
    height: 260px;
    width: 300px;
  }
}

.cardImage {
  max-width: 100%;
  max-height: 100%;
}

.cardTextWrap {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.cardText {
  margin-bottom: 22px;
}
