@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.inputWrapper {
  background-color: #2C2C2C;
  border-radius: $border-radius-sm;
  padding: 0;
  width: 100%;
  & > .input {
    border-radius: $border-radius-sm;
    color: $color-root;
    padding: 11px;
    background: transparent;
    width: 100%;
    height: 48px;
    font-size: 18px;
    border: 2px solid transparent;
    &::placeholder {
      color: rgb(122, 112, 141);
    }
    &:focus {
      border: 2px solid #F70A61;
    }
    &:disabled {
      cursor: no-drop;
    }
  }
}
