.loader{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px dotted #dcefff;
  animation: rotate 2s infinite ease;
}
@keyframes rotate {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(1turn);
  }
}
