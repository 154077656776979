@import '../../../../assets/styles/mixins';

.container {
  width: 100%;
}

.step {
  @include flexbox();
  flex-wrap: wrap;
}

.label {
  font-size: 18px;
  width: 70%;
  font-weight: 400;
  padding-left: 10px;
  max-height: 52px;

  @include tablet {
    width: 45%;
    padding-left: 0;
  }
}

.title {
  @include flexbox();
  justify-content: center;
  width: 15%;

  @include tablet {
    width: 10%;
  }
}

.divider {
  width: 80%;
  height: 51px;
  margin-left: 10%;
  border-left: 2px solid #633663;

  @include tablet {
    width: 50%;
    margin-left: auto;
  }
}

.number {
  @include flexbox();

  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  align-items: center;
  justify-content: center;
  border: 2px solid #633663;
  border-radius: 100%;
  color: #633663;
  font-size: 24px;
  font-weight: 500;
}

.empty {
  width: 0;

  @include tablet {
    width: 45%;
  }
}

.firstNumber {
  margin-left: 1px;
}

.secondNumber {
  margin-left: 1px;
}
