@import '../../assets/styles/mixins';

.factory {
  padding-top: 45px;
  padding-bottom: 15px;
  min-width: 100%;
  min-height: calc(100vh - 90px);
  background-color: #000;
  background-image: url("../../assets/images/lending/Factory/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include tablet {
    padding: 120px 0;
    background-image: url("../../assets/images/lending/Factory/bgBig.png");
  }

  &Title {
    margin-bottom: 40px;
    font-size: $font-size-title-xs;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      margin-bottom: 100px;
      font-size: $font-size-title-lg;
    }
  }

  &TitleH {
    font-weight: 500;
  }

  &Cards {
    @include flexbox(center, center, row);
    flex-wrap: wrap;
    gap: 20px 0;

    @include tablet {
      gap: 20px;
    }

    @include tablet-big {
      margin: 0 auto;
      max-width: 1250px;
    }
  }
}

.container {
  width: 100%;
  padding: 0 15px;
}

.card {
  @include flexbox(flex-start, space-between, column);
  padding: 15px 10px 10px;
  height: 250px;
  border-radius: $border-radius-lg;
  background: linear-gradient(-90deg, #3A2266 0%, #5C1521 100%);

  &Title {
    display: flex;
    align-items: center;
  }

  &Img {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  &Sum {
    display: flex;
    height: 48px;

    &Numb {
      padding-left: 20px;
      width: 143px;
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      color: inherit;
      background: #2C2C2C;
    }

    &Btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);

      &Disable {
        color: rgba(255, 255, 255, 0.5);
        background: linear-gradient(90deg, rgba(247, 10, 97, 0.5) 0%, rgba(211, 61, 163, 0.5) 50.38%, rgba(177, 107, 223, 0.5) 100%);
      }
    }
  }

  &Btn {
    height: 48px;
  }
}

.colorSize {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.loader {
  width: 30px;
  height: 30px;
}