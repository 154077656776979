@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.info {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 100%;


    @include tablet-big {
      max-width: 992px;
    }

    .title {
      padding: 0 16px;
      font-weight: 275;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 20px;
    }
  }
}

.tableWrapper {
  width: 100%;
  overflow: auto;
  padding: 0 16px 110px;

  @include tablet {
    padding-bottom: 220px;
  }
  table {
    border-spacing: 0;
    width: 960px;

    th, td {
      position: relative;
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 60px;

      color: #FFFFFF;

      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      padding: 0;

      .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;

        color: #FFFFFF;

        b {
          font-weight: 600;
        }

        .icon {
          width: 16px;
          height: 16px;

          margin-right: 10px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    td {
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);

      &.from {
        color: #00C2FF;
      }
    }
  }
}
