@import '../../assets/styles/mixins';

.container {
  overflow: hidden;
  width: 100%;
  margin-top: 80px;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background:  linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
}