@import '../../../../assets/styles/mixins';

.container {
  padding: 0 15px;
}

.car {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: $border-radius-lg;
  font-size: $font-size-text-sm;
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);

  @include tablet {
    padding: 50px 0;
    width: 695px;
    flex-direction: row;
    min-height: 550px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: $border-radius-lg;
  }

  .card {
    @include tablet {
      margin-top: 25px;
      margin-right: 30px;
    }
    &Imd {
      position: relative;

      @include tablet {
        margin-bottom: 20px;
      }

      &Main {
        width: 100%;
      }

      &Name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    &Text {
      display: none;
      padding-left: 15px;

      @include tablet {
        position: relative;
        display: block;
      }

      &List {
        margin-top: 20px;

        & > * {
          margin-top: 20px;
        }

        & > :first-child {
          margin-top: 0;
        }
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 15px 10px 20px;
    width: 100%;

    @include tablet {
      padding: 0;
      padding-right: 50px;
    }

    &Title {
      margin-bottom: 20px;
      font-size: $font-size-title-lg;
      text-transform: uppercase;
    }

    &Price {
      margin-bottom: 20px;
      font-size: $font-size-text-xs;
    }

    &Link {
      margin-bottom: 30px;

      .link {
        color: rgba(255, 255, 255, 0.75);
        text-decoration-line: underline;
      }
    }

    &List {
      margin-bottom: 30px;
      padding-left: 20px;
      font-size: $font-size-text-xs;
    }
  }
}

.carTechno {
  &::before {
    background: linear-gradient(90deg, #F71661 0%, rgba(247, 21, 97, 0) 100%), rgba(0, 0, 0, 0.2);
  }
}

.carAwoken {
  &::before {
    background: linear-gradient(90deg, #7A39D3 0%, rgba(122, 57, 211, 0) 100%), rgba(0, 0, 0, 0.2);
  }

  .cardImdMain {
    transform: scaleX(-1);
  }

  .buttonMint {
    background: linear-gradient(90deg, #B269DC 0%, #7A39D3 100%);
  }
}

.buttonMint {
  padding: 15px 0;
  text-transform: uppercase;
}

.descriptionDrop {
  margin-bottom: 20px;
}