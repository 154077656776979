@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  height: 100%;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
  min-height: 429px;
  width: 100%;

  @include tablet {
    min-height: 600px;
  }

  @include tablet-big {
    min-height: 700px;
  }
}

.wrap {
  @include flexbox();
  flex-direction: column;
  width: 100%;
  max-width: 768px;

  @include tablet-big {
    max-width: 960px;
  }
}

.title {
  margin: 55px 0 60px;

  @include tablet {
    margin: 68px 0 133px;
  }

  @include tablet-big {
    margin: 68px 0 185px;
  }
}
