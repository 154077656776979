@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.inputWrapper {
  width: 100%;
  height: 48px;

  & .selected {
    display: flex;
    justify-content: space-between;
    & > .selectedLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #FF6E4D;
    }
  }

  :global(.MuiSvgIcon-root) {
    display: none;
  }
  :global(.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input), :global(.MuiSelect-select) {
    height: 100%;
    padding: 0;
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }

  .button {
    width: 100%;
    height: 100%;
    &:hover {
      transform: none;
    }
  }
}
