@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.list {
  position: relative;
  min-height: 540px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    display: flex;
    gap: 26px;
    width: 100%;
    overflow: auto;
    max-width: 990px;
    padding: 90px 16px;
  }
}
