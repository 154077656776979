@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.input {
  background-color: #2C2C2C;
  color: rgba(255,255,255,0.5);
  height: 48px;
  width: 100%;
  padding: 0 24px 0 48px;
  font-size: 16px;
  border-radius: 4px;

  .icon {
    position: absolute;
    left: 14px;
    width: 22px;
    height: 22px;
  }

  & :disabled {
    color: rgba(255,255,255,0.5) !important;
  }
}
