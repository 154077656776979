@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.headerWrapper {
  position: fixed;
  z-index: 1000;
  @include flexbox();
  width: 100%;
  background: linear-gradient(90deg, #5C1521 0%, #701523 20.31%, #38172A 52.08%, #462484 81.25%, #3A2266 100%);
}

.header {
  @include flexbox(center, space-between);
  width: 100%;
  padding: 22px 24px;
}

.logo {
  display: flex;
  align-items: center;
  &Icon {
    margin-right: 20px;
    width: 144px;
    z-index: $burger-container-z-index;
  }
}

.link {
  white-space: nowrap;
  margin-right: 24px;
  color: $color-root;
  font-weight: 400;
  font-size: $font-size-text-sm;
  padding-bottom: 6px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color $animation-time;

  &:hover {
    border-bottom: 1px solid $color-pink;
  }
}

.linkActive {
  color: $color-pink;
}

.navigationWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  display: none;
  margin-left: 0;
  padding-top: 10px;

  @include tablet-header {
    display: flex;
  }
}

.navigation :last-child {
  margin-right: 0;
}

.walletWrapper {
  display: none;
  align-items: center;
  width: 170px;

  @include tablet-header {
    display: flex;
  }
}

.walletAddress {
  margin: 0 0 15px;
  white-space: nowrap;
  width: 100%;


  @include mobile {
    margin: 0 10px 0;
  }
}

.burger {
  display: block;
  @include tablet-header {
    display: none;
  }
}
