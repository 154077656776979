@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2C2C2C;
  border-radius: $border-radius-sm;
  padding: 0;
  width: 100%;

  & > .input {
    border-radius: $border-radius-sm;
    color: $color-root;
    padding: 11px;
    background: transparent;
    min-width: 0;
    height: 48px;
    font-size: 18px;
    width: 100%;
    border: 2px solid transparent;
    &::placeholder {
      color: rgb(122, 112, 141);
    }
    &:disabled {
      cursor: no-drop;
    }
  }
  .priceIcon {
    width: 64px;
    height: 30px;
    padding: 0 20px;
    border-left: 1px solid rgb(122, 112, 141);
  }
}
