@import '../../../assets/styles/mixins';

.container {
  width: 100%;
  min-height: calc(100vh - 92px);
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);
}

.preloader {
  min-height: calc(100vh - 92px);
}
