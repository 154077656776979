@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  flex-direction: column;
  position: relative;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
  width: 100%;
}

.title {
  margin: 85px 0 40px;

  @include mobile {
    margin-bottom: 50px;
  }

  @include tablet-big {
    margin-bottom: 70px;
  }
}

.titleBold {
  font-weight: 400;
}

.text1 {
  margin-bottom: 24px;
}

.text2 {
  margin-bottom: 50px;
}

.wrapper {
  @include flexbox();
  flex-direction: column;
  max-width: 768px;
  padding: 0 34px;

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
  }

  @include tablet-big {
    max-width: 960px;
    align-items: center;
  }
}

.imgWrap {
  margin-bottom: 42px;

  @include tablet {
    margin-right: 40px;
    margin-bottom: 102px;
  }
}

.button {
  height: 48px;
}

.link {
  width: 100%;
  max-width: 240px;
}

.buttonWrap {
  @include flexbox();
  width: 100%;
  margin-bottom: 69px;
  justify-content: center;

  @include tablet {
    justify-content: start;
    margin-bottom: 82px;
  }

  @include mobile {
    margin-bottom: 89px;
  }
}
