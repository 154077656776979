@import './variables';
@import './montserrat';

$base-font: 'montserrat',
sans-serif,
;

* {
  box-sizing: border-box;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

html,
body,
div[id="root"] {
  font-family: $base-font;
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-size: inherit;
  font-family: $base-font;
  font-weight: 300;
}

body {
  color: $color-root;

  &.ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

html,
body,
button {
  font-family: $base-font;
}

body.body-overflow {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

p, ul, ol {
  margin: 0;
  padding: 0;
}

button, input {
  padding: 0;
  border: none;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
}

