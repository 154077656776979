@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);
  width: 100%;
  flex-direction: column;
  position: relative;
}

.banner {
  @include flexbox();
  flex-direction: column;
  width: 100%;
  background: url("../../../assets/images/gamefi/gamefi_01.png");
  height: 440px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  @include mobile-sm {
    height: 480px;
  }

  @include mobile {
    height: 600px;
  }

  @include tablet {
    height: 719px;
  }

  @include desktop-big {
    background-size: contain;
  }
}

.title {
  margin-bottom: 509px;
  margin-top: 28px;
}

.titleBold {
  font-weight: 400;
}

.logo {
  margin-top: 235px;
  width: 299px;
  height: 41px;

  @include mobile {
    margin-top: 140px;
    width: 427px;
    height: 59px;
  }

  @include tablet {
    margin-top: 94px;
  }
}

.textWrapper {
  max-width: 350px;
  margin: 30px 34px 0px;
  padding-bottom: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-bottom: solid 1px rgba(255, 255, 255, 0.25);

  @include mobile {
    max-width: 756px;
    padding-bottom: 80px;
  }

  @include tablet {
    max-width: 960px;
  }
}

.text {
  font-size: 14px;
  line-height: 27px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 25px;

  @include mobile {
    font-size: 24px;
  }
}

.button {
  position: absolute;
  z-index: $toast-container-z-index;
  bottom: -28px;

  @include tablet-big {
    bottom: -45px;
  }
}

