@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/variables';

.slideWrap {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 670px;
  width: 100%;
  background-image: url("../../../../../assets/images/lending/carousel/slide2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -280px;

  @include tablet {
    flex-direction: row;
    background-position: -453px;
    justify-content: center;
  }

  @include desktop-min {
    background-position: bottom;
  }
}

.img {
  width: 230px;
  margin-bottom: 10px;
  margin-right: 0;

  @include tablet {
    margin-right: 5%;
    margin-top: 32px;
  }

  @include tablet-slider {
    margin-bottom: 0;
  }

  @include desktop-sm-slider {
    width: 360px;
  }
}

.slide {
  @include mobile {
    margin-right: 0%;
  }

  @include tablet-big {
    margin-right: 0;
  }
}

.title {
  text-align: center;
  font-weight: 300;
  font-size: 48px;

  @include tablet {
    text-align: left;
  }

  @include tablet-big {
    font-size: 64px;
  }
}

.text {
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin: 30px 0 25px;

  @include tablet {
    text-align: left;
  }

  @include tablet-slider {
    margin-bottom: 35px;
  }

  @include tablet-big {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 50px;
  }
}

.button {
  height: 48px;
}

.link {
  width: 100%;
  max-width: 240px;
}

.buttonWrap {
  width: 240px;

  @include tablet-slider {
    margin-bottom: 114px;
  }

  @include tablet-slider {
    margin-bottom: 0;
  }

  @include tablet-big {
    margin-bottom: 0;
  }
}
