@import '../../assets/styles/mixins';

.container {
  width: 100%;
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);
  min-height: calc(100vh - 180px);
  margin-top: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .loader {
    margin-top: 100px;
    margin-bottom: 100px;
    width: 30px;
    height: 30px;
  }

  .connect {
    padding: 120px 16px;
    max-width: 960px;
    width: 100%;
    height: 38px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &Text {
      font-weight: 275;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 40px;
      text-align: center;

      @include tablet {
        font-size: 32px;
      }
    }

    &Button {
      max-width: 250px;
    }
  }
}
