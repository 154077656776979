@import '../../assets/styles/variables';

$animation-duration: 1.5s;
$delay-range: 0.3s;

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.wrap.normal {
  $size: 25px;
  padding: 60px 0;
}

.wrap.big {
  $size: 40px;
  padding: 80px 0;
}

.wrap.small {
  $size: 18px;
  padding: 20px 0;
}
