@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.info {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 100%;
    max-width: 752px;
    padding: 0 16px;

    @include tablet-big {
      max-width: 992px;
    }

    .mainInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-top: 106px;
      padding-bottom: 24px;

      @include tablet {
        padding-top: 185px;
        flex-direction: row;
      }
      .imgContainer {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        max-width: 340px;
        height: auto;
        line-height: 0;
        .img {
          width: 100%;
          height: auto;
        }

        @include tablet-big {
          max-width: 440px;
        }
      }

      &Container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 340px;
        min-height: auto;

        @include tablet {
          min-height: 340px;
        }

        @include tablet-big {
          max-width: 440px;
          min-height: 440px;
        }

        .mainInfoTop {

        }
        .mainInfoBottom {

        }

        .priceContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 48px;
          background-color: #2C2C2C;
          border-radius: 4px;

          margin-bottom: 10px;

          .price {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.5);

            margin-right: 6px;
          }

          .priceIcon {
            width: 20px;
            height: 20px;
          }
        }

        .buyButton {
          min-height: 48px;
        }

        .name {
          font-weight: 400;
          font-size: 32px;
          line-height: 39px;
          color: #FFFFFF;

          margin: 0 0 10px;
        }

        .subinfo {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;

          color: rgba(255, 255, 255, 0.5);

          margin-bottom: 15px;
        }

        .tagsRow {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          justify-content: flex-start;

          margin-bottom: 24px;
        }

        .rank {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;

          margin-bottom: 18px;
        }

        .tag {
          padding: 1px 4px;
          border-radius: 3px;
          line-height: 1;

          &Text {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
    .title {
      font-weight: 275;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 20px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
