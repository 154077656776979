@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  flex-direction: column;
  width: 100%;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
  min-height: 900px;
}

.title {
  margin-bottom: 10px;
  margin-top: 79px;
}

.titleBold {
  font-weight: 400;
}

.subtitle {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 60px;
  }
}
