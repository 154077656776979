@import '../../assets/styles/mixins';

.button {
  width: 240px;
  border: none;
  outline: none;
  color: white;
  background: #fca52d;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  font-weight: 600;
  font-size: 30px;
  padding: 10px;
  text-shadow: 0px 0px 10px #FFFFFF, 0px -1px 0px #000000;
  filter: drop-shadow(0px 0px 15px #D002FF);

  @include tablet-big {
    width: 400px;
    padding: 18px;
    font-size: 42px;
  }
}

.button:before {
  content: '';
  background: linear-gradient(
                  90deg,
                  #47151B,
                  #ffffff,
                  #2C1C46
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.button:active {
  color: #000;
}

.button:active:after {
  background: transparent;
}

.button:hover:before {
  opacity: 1;
  background: linear-gradient(90deg, #FFFFFF 0%, #F5F4F5 100%);
}

.button:hover {
  background-clip: inherit;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 50px #D002FF);
}

.button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #110724;
  left: 0;
  top: 0;
}

.button:hover:after {
  background: linear-gradient(90deg, #FFFFFF 0%, #F5F4F5 100%);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
