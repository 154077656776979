@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.contractInfo {
  display: flex;
  align-items: center;
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    .icon {
      line-height: 0;
      width: 64px;
    }
    margin-right: 20px;
  }
  .info {
    .text {
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;

      @include tablet {
        font-size: 24px;
        line-height: 29px;
      }
    }
    .link {
      word-break: break-word;
      display: block;
      text-decoration: underline 1px #00C2FF;
      text-underline-offset: 2px;
      color: #00C2FF;
    }
  }
}

