@import '../../../../assets/styles/mixins';

.containerWrap {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  max-width: 900px;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 80px;
}

.wrap {
  padding: 0 12px;
  margin-bottom: 80px;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div {
    width: 100%;
  }
}

.logo {
  max-width: 180px;
  max-height: 180px;
}

.logoWrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button {
  cursor: pointer;
  background: transparent;
  border: none;
}
