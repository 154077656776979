@import '../../assets/styles/mixins';

.container {
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg, #2C1D47 0%, #48151B 100%);
  width: 100%;
  min-height: calc(100vh - 90px);
}

.blog {
  padding: 80px 24px 120px;

  .title {
    margin-top: 40px;
    margin-bottom: 37px;
    text-align: center;

    @include tablet {
      margin-top: 74px;
      margin-bottom: 84px;
    }

    @include tablet-big {
      margin-bottom: 104px;
    }
  }

  .subtitle {
    font-weight: 400;
  }
}

.newsArticles {
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-auto-flow: row dense;
  grid-gap: 67px 20px;

  .article {
    width: 100%;
    height: auto;
    transition: transform $animation-time;
    grid-column: span 2;

    @include tablet {
      grid-column: span 1;
    }

    img {
      filter: drop-shadow(0px 0px 30px #9E00FF);
      width: 100%;
      max-width: 968px;
      max-height: 512px;
      height: 52vw;

      @include tablet {
        max-width: 470px;
        max-height: 250px;
        height: 24vw;
      }
    }

    .newsArticleTitle {
      font-weight: 500;
      font-size: 18px;
      margin: 15px 0 9px;

      @include tablet-big {
        font-size: 21px;
      }
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.75);
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.newsArticles > :first-child {
  grid-column: span 2;
  max-width: 100%;

  img {
    width: 100%;
    max-width: 968px;
    max-height: 512px;
    height: 52vw;
  }

  .newsArticleTitle {
    font-weight: 500;
    font-size: 18px;
    margin: 15px 0 9px;

    @include tablet {
      font-size: 36px;
    }

    @include tablet-big {
      font-size: 48px;
    }
  }

  .date {
    font-weight: 400;
    font-size: 12px;

    @include tablet {
      font-size: 18px;
    }

    @include tablet-big {
      font-size: 24px;
    }
  }
}

.loader {
  @include flexbox();
}
