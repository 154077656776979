@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.media {
  cursor: pointer;
  margin-right: 6px;
  width: 32px;
  height: 32px;
  transition: transform $animation-time;

  &:hover {
    transform: translate(2px, -2px);
  }
}

.container {
  display: flex;
}

.container :last-child {
  margin-right: 0;
}
