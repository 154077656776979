@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.main {
  @include flexbox(center, flex-start);
  background: $background-color-root;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}