@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.container {
  @include flexbox();
  position: relative;
  height: 36px;
  width: 35px;
}

.menu {
  @include flexbox(flex-start, flex-start);

  position: fixed;
  top: 0;
  right: 0;
  z-index: $menu-container-z-index;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 0 23px;
  background: rgba(21, 21, 21, 0.75);
  transition: height 0.5s;
}

.open {
  height: 100%;
  padding-top: 80px;
}

.burger {
  margin-top: 5px;
  position: absolute;
  z-index: $burger-container-z-index;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  border: none;
  background: transparent;
}

.link {
  font-size: 24px;
  line-height: 29px;
  transition: text-shadow 0.1s, color 0.1s;
  margin-bottom: 26px;

  &:hover {
    color: #F9508D;
    text-shadow: 0px 0px 10px #FF008A;
  }
}

.linkList {
  padding-top: 40px;
}

.listItem {
  margin-bottom: 40px;
  opacity: 0;
}

.listItemShow {
  opacity: 1;
}

.button {
  width: 100%;
  margin-right: 16px;
}

.buttonWrap {
  @include flexbox;

  width: 100%;
  margin-bottom: 23px;
}

.buttonWrap > :last-child {
  margin-right: 0;
}


