@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Light.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Thin.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Medium.ttf);
  font-weight: 500;
}
