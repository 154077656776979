@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.card {
  min-width: 220px;
  max-width: 220px;
  height: 382px;

  overflow: hidden;

  border-radius: 10px;

  background: linear-gradient(-90deg, #3A2266 0%, #5C1521 100%);

  .imgWrapper {
    height: 220px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img {
    display: block;
    height: 100%;
    width: auto;
    &Load {
      display: none;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 14px 20px;

    .name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;

      margin: 0 0 8px;
    }

    .row {
      display: flex;
      justify-content: space-between;

      margin-bottom: 5px;
    }

    .subinfo {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.5);
    }

    .tagsRow {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: flex-start;
    }

    .tagsEllipsis {
      padding: 1px 4px;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 3px;
    }

    .currency {
      display: flex;
      &Icon {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }

    .rank {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;

      margin-bottom: 16px;
    }
  }
}

.tag {
  padding: 1px 4px;
  border-radius: 3px;
  line-height: 1;

  &Text {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }
}
