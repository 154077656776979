@import '../../assets/styles/variables';

.modal {
  margin: 0;
  position: relative;
}

.closeModalWrapper {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: $toast-container-z-index;
  cursor: pointer;
}

.closeModal {
  fill: $color-root;
}