@import '../../assets/styles/mixins';

.container {
  width: 100%;
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);
  min-height: calc(100vh - 180px);

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px 20px 190px;

  @include mobile {
    padding-top: 64px;
  }

  @include tablet {
    padding-top: 165px;
  }

  &:before {
    content: '';
    height: 639px;
    left: 0;
    right: 0;
    bottom: 0;

    position: absolute;
    background: linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0) 100%);
    transform: rotate(-180deg);
  }

  .title {
    position: relative;
    font-weight: 275;
    font-size: 48px;
    line-height: 59px;

    text-align: center;

    color: #FFFFFF;

    margin-bottom: 40px;
    @include mobile {
      margin-bottom: 64px;
    }
    @include tablet {
      margin-bottom: 116px;
    }
  }

  .contracts {
    position: relative;
    .contract {
      margin-bottom: 30px;
    }
  }
}
