@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.list {
  position: relative;
  min-height: 640px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 34px 16px;

  &:after {
    content: '';
    height: 639px;
    left: 0;
    right: 0;
    bottom: 0;

    position: absolute;
    background: linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0) 100%);
    transform: rotate(-180deg);
    z-index: 1;
  }

  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    width: 100%;
    max-width: 220px;
    z-index: 2;

    @include mobile {
      max-width: 470px;
    }

    @include tablet {
      max-width: 712px;
    }

    @include tablet-big {
      max-width: 958px;
    }
  }
}
