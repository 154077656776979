@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.arrow {
  transition: transform $animation-time;
  z-index: $tooltip-z-index;
  display: none;
}

.arrowDown {
  display: flex;
}

.arrowUp {
  display: flex;
  transform: rotate(180deg);
}
