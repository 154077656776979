@import '../../assets/styles/mixins';

.mint {
  margin-top: 80px;
  @include flexbox(center, start, column);
  position: relative;
  padding-top: 20px;
  padding-bottom: 150px;
  width: 100%;
  background: linear-gradient(270deg, #2C1D47 0%, #2C1623 50.3%, #48151B 100%);

  @include tablet-big {
    padding-top: 90px;
  }
}

.blockDecor {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 640px;
  background: linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0) 100%);
  transform: rotate(-180deg);
}

.container {
  @include flexbox(center, start, column);
  position: relative;
  padding: 0 15px;
}

.blockButtons {
  @include flexbox();
  margin-bottom: 10px;
  width: 100%;

  @include tablet-big {
    display: none;
  }

  .buttonCar {
    @include flexbox();
    width: 50%;
    height: 60px;
    border-radius: $border-radius-sm;
    border-bottom: solid transparent 4px;
    background: linear-gradient(90deg, #F60B62 0%, #D33DA3 100%);

    &Awoken {
      padding-top: 5px;
      background: linear-gradient(90deg, #B269DC 0%, #7A39D3 100%);
    }

    &Active {
      border-bottom: solid #fff 4px;
    }
  }
}

.blockCar {
  font-size: $font-size-text-sm;

  @include tablet-big {
    display: flex;
    justify-content: space-between;
    width: 960px;
  }

  .car {
    display: none;
    max-width: 470px;
    width: 100%;

    @include tablet-big {
      @include flexbox(center, center, column);
    }

    &Active {
      @include flexbox(center, center, column);
    }

    .card {
      @include flexbox(center, center, column);
      height: 645px;
      border-radius: $border-radius-lg;

      @include tablet-big {
        margin-bottom: 100px;
      }

      &Imd {
        position: relative;
        margin-bottom: 40px;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &Main {
          width: 100%;
        }

        &Name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }

      &Text {
        margin-bottom: 40px;
        text-align: center;

        &List {
          font-size: $font-size-text-lg;

          & > * {
            padding-top: 25px;
          }
        }
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tablet-big {
        @include flexbox(center, space-between, column);
        padding-bottom: 25px;
        width: 435px;
        height: 600px;
      }

      &Title {
        @include flexbox(flex-end);
        margin-bottom: 40px;
        height: 100px;
      }

      &Text {
        margin-bottom: 40px;
        overflow: auto;
        font-size: $font-size-text-sm;
        scrollbar-width: none;

        @include tablet-big {
          height: 480px;
        }

        & > * {
          margin-top: 20px;
        }

        & > :first-child {
          margin-top: 0;
        }

        &::-webkit-scrollbar {
          background: rgba(255, 255, 255, 0.25);
          width: 2px;
        }
        &::-webkit-scrollbar-button {

        }
        &::-webkit-scrollbar-track {

        }
        &::-webkit-scrollbar-track-piece {

        }
        &::-webkit-scrollbar-thumb {
          background: rgb(165, 70, 68);
        }
        &::-webkit-scrollbar-corner {

        }
        &::-webkit-resizer {
          width: 2px;
        }
      }
    }

    .buttonMint {
      padding: 15px 0;
      width: 185px;
      text-transform: uppercase;
    }
  }

  .carTechno {
    //display: none;

    .card {
      background: linear-gradient(180deg, #F51764 0%, rgba(245, 23, 100, 0) 100%), rgba(0, 0, 0, 0.2);
    }

    .description {
      &Title {
        margin-top: 35px;

        @include tablet-big {
          margin-top: 0;
        }
      }

      @include tablet-big {
        &:before {
          content: '';
          position: absolute;
          right: 50%;
          display: block;
          width: 2px;
          height: 770px;
          background: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }

  .carAwoken {
    //display: none;

    .card {
      background: linear-gradient(180deg, #7A39D3 0%, rgba(122, 57, 211, 0) 100%), rgba(0, 0, 0, 0.2);
    }

    .buttonMint {
      background: linear-gradient(90deg, #B269DC 0%, #7A39D3 100%);
    }
  }
}
