@import '../../../assets/styles/mixins';

.container {
  width: 100%;
  background: linear-gradient(90deg, #5C1521 0%, #701523 20.31%, #38172A 52.08%, #462484 81.25%, #3A2266 100%);
  height: 339px;
}

.wrap {
  @include flexbox();
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/lending/mintBottom/bg.png');
  background-repeat: no-repeat;
  background-position-x: -1275px;

  @include mobile-sm {
    background-position-x: -1100px;
  }

  @include desktop-min {
    background-position-x: -900px;
  }

  @include desktop {
    background-position-x: -700px;
  }

  @include desktop-md {
    background-position: inherit;
    background-size: cover;
  }

  @include desktop-2k {
    background-position: inherit;
  }
}
