@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.button {
  width: 100%;
  @include flexbox();
  cursor: pointer;
  border-radius: $border-radius-sm;
  padding: 8px 20px;
  color: $color-root;
  background: linear-gradient(90deg, #F70A61 0%, #D33DA3 50.38%, #B16BDF 100%);;
  border: none;
  font-size: $font-size-text-sm;
  font-weight: 400;
  transition: transform $animation-time;

  position: relative;

  &:hover {
    transform: translate(2px, -2px);
  }

  &:disabled {
    background: linear-gradient(90deg, rgba(247, 10, 97, 0.5) 0%, rgba(211, 61, 163, 0.5) 50.38%, rgba(177, 107, 223, 0.5) 100%);
    cursor: no-drop;
    color: rgba(255, 255, 255, 0.5);
  }

  &:active {
    background: linear-gradient(90deg, rgba(247, 10, 97, 0.5) 0%, rgba(211, 61, 163, 0.5) 50.38%, rgba(177, 107, 223, 0.5) 100%);
    cursor: no-drop;
    color: rgba(255, 255, 255, 0.5);
  }
}

.icon {
  display: flex;
  align-items: center;
  left: 15px;
  position: absolute;
  margin-right: 20px;
  min-height: 24px;
  min-width: 24px;
}
