@import '../../../assets/styles/mixins';

.container {
  @include flexbox();
  width: 100%;
  background: url('../../../assets/images/lending/carousel/slide3.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
  justify-content: center;
  position: relative;
  z-index: 6;
  background-position: center;
}

.lab {
  @include flexbox();
  flex-direction: column;
  align-items: center;
  position: absolute;

  @include mobile {
    position: relative;
    min-width: 0;
    margin-left: 15px;
    align-items: flex-start;
  }

  @include tablet-big {
    margin-left: 30px;
    margin-right: 56px;
  }
}

.title {
  width: 300px;
  margin-bottom: 5px;
  margin-top: 62px;
  order: 0;
  text-align: center;

  @include mobile {
    order: initial;
    margin-bottom: 42px;
    margin-top: 89px;
    width: 360px;
    text-align: initial;
  }

  @include tablet-big {
    width: 412px;
  }
}

.titleBold {
  font-weight: 400;
}

.labImage {
  margin-bottom: 370px;
  width: 240px;
  order: 2;

  @include mobile {
    order: initial;
    margin-bottom: 21px;
    width: 360px;
  }

  @include tablet-big {
    width: 482px;
  }
}

.manImage {
  margin-top: auto;
  position: absolute;
  bottom: -245px;
  z-index: 7;

  @include mobile {
    position: relative;
    bottom: initial;
    z-index: initial;
  }

  @include tablet-big {
    margin-right: 30px;
  }
}

.text {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 41px;
  order: 1;

  @include mobile {
    order: initial;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 88px;
  }
}
