@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.select {
  &::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0.25);
    width: 2px;
  }
  &::-webkit-scrollbar-button {

  }
  &::-webkit-scrollbar-track {

  }
  &::-webkit-scrollbar-track-piece {

  }
  &::-webkit-scrollbar-thumb {
    background: rgb(165, 70, 68);
  }
  &::-webkit-scrollbar-corner {

  }
  &::-webkit-resizer {
    width: 2px;
  }
}
